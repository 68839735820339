.campaignSummarySection{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 4px 0;
    gap: 8px;
}

.campaignDetailsSectionContainer{
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.campaignSummaryFormatContainer{
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.campaignSummaryFormatBottomText{
    padding-top: 3px;
}
.modalBackground {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.modalContent {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding: 20px 20px 84px 20px;
  width: 869px;
  height: calc(90vh - 60px);
}

.modalHeader {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;
}

.closeButton {
  cursor: pointer;
  padding: 0;
}

.modalTitleContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 16px;
}

.modalTitle {
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
}

.audienceNameWrapper,
.audienceDescriptionWrapper,
.downloadTemplateButtonWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.audienceDescriptionWrapper,
.downloadTemplateButtonWrapper {
  color: #0c172c;
  padding-left: 10px;
}

.audienceNameError {
  margin-top: 2px;
  color: #e00404;
  display: flex;
  height: 5px;
}

.customListBullet {
  display: inline-block;
  margin-right: 5px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #2e3860;
}

.downloadTemplateButton {
  display: flex;
  align-items: center;
  font-weight: bold;
  text-decoration: underline;
  max-width: max-content;
  cursor: pointer;
  padding: 0;
}

.downloadTemplateButton svg {
  margin-right: 5px;
}

.dragAndDropWrapper {
  align-self: flex-start;
  width: 70%;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  height: 162px;
  padding: 10px;
  z-index: 1;
  border-radius: 8px;
  border: 1px solid #e2e2fc;
}

.required {
  color: red;
}

.fileError {
  width: 100%;
  padding: 12px 16px;
  background-color: #fde7e9;
  color: #e00404;
  display: flex;
  align-items: center;
}

.uploadedCSVFile {
  width: 80%;
  align-self: flex-start;
  padding: 12px 16px;
  background-color: #f2f2f6;
  color: #2e3860;
  display: flex;
  align-items: flex-start;
}

.fileName {
  font-size: 14px;
  font-weight: 500;
  line-height: 16.8px;
}

.rowsAmount {
  font-size: 12px;
  font-weight: 400;
  line-height: 14.4px;
}

.fileStick {
  margin-right: 5px;
}

.fileData {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.deleteIcon {
  cursor: pointer;
}

.footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  justify-self: flex-end;
  position: absolute;
  padding: 20px;
  bottom: 0;
  height: 81px;
  box-shadow: 0px -2px 16px 0px #253b6133;
  background-color: #ffffff;
  z-index: 1;
}

.createAudienceButtonSubmit {
  width: 200px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #e2e2fc;
  cursor: pointer;
  position: relative;
}

.cursorDefault {
  cursor: default;
}

.audienceHeadersMapperWrapper {
  margin-top: 30px;
  max-height: 30%;
  overflow: scroll;
  align-self: flex-start;
  display: flex;
  width: 80%;
  flex-direction: column;
}

.audienceHeadersMapperRow {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  gap: 10px;
  align-items: center;
  margin-bottom: 10px;
}

.setCsvColumnsTitle {
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 20px;
}

.summaryCardContainer{
    display: flex;
    padding-top: 24px;
    flex-direction: column;
    border-radius: 8px;
    background: #FFFFFF;
    box-shadow: 0px 2px 6px 0px rgba(39, 55, 84, 0.08);
    position: relative;
}

.campaignSummaryContainer{
    display: flex;
    flex-direction: column;
    padding: 0 32px;
    gap: 8px
}

.primaryButtonContainer{
    padding-top: 8px;
    padding-bottom: 16px;
    padding-left: 24px;
    padding-right: 24px;
}